<template>
  <div class="direction-container">
    <div class="container">
      <h2>{{ directionArray[0]?.title }}</h2>
      <div class="direction-container__list">
        <DirectionBlock
          v-for="(item, index) in directionArray"
          :key="index"
          :item="item.info_employees?.data[0]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DirectionBlock from "@/components/Blocks/DirectionBlock";
export default {
  name: "DirectionContainer",
  components: { DirectionBlock },
  props: {
    title: String,
    directionArray: Array,
  },
};
</script>

<style lang="scss">
.direction-container {
  margin-top: 80px;
  h2 {
    margin-bottom: 30px;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-items: center;
    gap: 70px;
  }
  @include adaptive(tablet-small) {
    &__list {
      gap: 20px;
    }
  }
}
</style>
