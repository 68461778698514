<template>
  <div class="direction">
    <DirectionContainer :direction-array="infoDirectorate" />
    <DirectionContainer :direction-array="infoMemberServices" />
    <DirectionContainer :direction-array="infoStaffServices" />
    <DirectionContainer :direction-array="infoApplicationServices" />
    <DirectionContainer :direction-array="infoPRServices" />
    <DirectionContainer :direction-array="infoMotivationServices" />
  </div>
</template>

<script>
import DirectionContainer from "@/components/DirectionSections/DirectionContainer";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
export default {
  name: "Direction",
  components: { DirectionContainer },
  data() {
    return {
      infoDirectorate: [],
      infoMemberServices: [],
      infoStaffServices: [],
      infoApplicationServices: [],
      infoPRServices: [],
      infoMotivationServices: [],
    };
  },
  beforeMount() {
    if (!this.$store.state.storage.storageDirectionData.length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "directorate",
          key: "directorate",
        },
      }).then((result) => {
        this.$store.commit("setDirectionData", result.data);
        this.infoDirectorate = this.$store.state.storage.storageDirectionData;
      });
    } else
      this.infoDirectorate = this.$store.state.storage.storageDirectionData;

    if (!this.$store.state.storage.storageDirectionMemberServiceData.length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "directorate",
          key: "member_services",
        },
      }).then((result) => {
        this.$store.commit("setDirectionMemberServiceData", result.data);
        this.infoMemberServices =
          this.$store.state.storage.storageDirectionMemberServiceData;
      });
    } else
      this.infoMemberServices =
        this.$store.state.storage.storageDirectionMemberServiceData;

    if (!this.$store.state.storage.storageDirectionStaffServiceData.length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "directorate",
          key: "staff_services",
        },
      }).then((result) => {
        this.$store.commit("setDirectionStaffServiceData", result.data);
        this.infoStaffServices =
          this.$store.state.storage.storageDirectionStaffServiceData;
      });
    } else
      this.infoStaffServices =
        this.$store.state.storage.storageDirectionStaffServiceData;

    if (!this.$store.state.storage.storageDirectionAppServiceData.length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "directorate",
          key: "z_application_services",
        },
      }).then((result) => {
        this.$store.commit("setDirectionAppServiceData", result.data);
        this.infoApplicationServices =
          this.$store.state.storage.storageDirectionAppServiceData;
      });
    } else
      this.infoApplicationServices =
        this.$store.state.storage.storageDirectionAppServiceData;

    if (!this.$store.state.storage.storageDirectionPRServiceData.length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "directorate",
          key: "pr_application_services",
        },
      }).then((result) => {
        this.$store.commit("setDirectionPRServiceData", result.data);
        this.infoPRServices =
          this.$store.state.storage.storageDirectionPRServiceData;
      });
    } else
      this.infoPRServices =
        this.$store.state.storage.storageDirectionPRServiceData;

    if (
      !this.$store.state.storage.storageDirectionMotivationServiceData.length
    ) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "directorate",
          key: "motivation_services",
        },
      }).then((result) => {
        this.$store.commit("setDirectionMotivationServiceData", result.data);
        this.infoMotivationServices =
          this.$store.state.storage.storageDirectionMotivationServiceData;
      });
    } else
      this.infoMotivationServices =
        this.$store.state.storage.storageDirectionMotivationServiceData;
  },
};
</script>

<style scoped></style>
