<template>
  <div class="direction-block">
    <div class="direction-block__img">
      <img :src="item.avatar" alt="" />
    </div>
    <div class="direction-block__name">
      {{ item.first_name }} {{ item.last_name }}
    </div>
    <div class="direction-block__profession">
      {{ item.info_positions.data[0].title }}
    </div>
    <div class="direction-block__icons">
      <a
        target="_blank"
        v-for="(social, index) in item.socials.data"
        :key="index"
        :href="social.href"
      >
        <Icon :type="social.type" />
      </a>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Blocks/Icon";
export default {
  name: "DirectionBlock",
  components: { Icon },
  props: {
    item: Object,
  },
};
</script>

<style lang="scss">
.direction-block {
  padding: 35px 40px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  box-shadow: 4px 4px 36px rgba(41, 41, 41, 0.1);
  &__img {
    margin-bottom: 25px;
    width: 220px;
    height: 220px;
    border-radius: 100%;
    background-color: var(--btn-blue-color1);
    overflow: hidden;
    flex-shrink: 0;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &__name {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--black2);
    text-align: center;
  }
  &__profession {
    text-align: center;
    color: var(--btn-gray-color1);
    margin-bottom: 25px;
  }
  &__icons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    a {
      color: var(--btn-gray-color1);
    }
    i {
      font-size: 21px;
      &:hover {
        -webkit-text-stroke-width: 0.5px;
      }
    }
  }
  @include adaptive(tablet-small) {
    max-width: 280px;
    width: 100%;
    padding: 20px;
    &__img {
      width: 180px;
      height: 180px;
    }
  }
}
</style>
